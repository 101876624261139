import { useFm } from '@better-bit-fe/base-hooks';
import React, { useEffect, useState, PropsWithChildren } from 'react';
import getConfig from 'next/config';
import { IRefer } from '~/types';
import styles from './index.module.less';
const { staticFolder } = getConfig().publicRuntimeConfig;

const Roadmap = (props: PropsWithChildren<IRefer>) => {
  const t = useFm();

  return (
    <section
      className={`section large top-120px   overflow-hidden wf-section ${styles.roadmap}`}
    >
      <div className="container-default w-container">
        <div className="mg-bottom-64px">
          <div className="inner-container _585px">
            <div className="inner-container _500px---tablet">
              <div className="inner-container _450px---mbl">
                <div className="inner-container _350px---mbp">
                  <h2
                    data-w-id="7c97b945-6c81-2d8b-d6ab-9e41df00ded8"
                    className="display-2 mg-bottom-0"
                    dangerouslySetInnerHTML={{ __html: t('h-6-t') }}
                  ></h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div
            data-delay="4000"
            data-animation="slide"
            className="timeline-slider w-slider"
            data-autoplay="false"
            data-easing="ease"
            // style="opacity:0"
            data-hide-arrows="false"
            data-disable-swipe="false"
            data-w-id="b3651333-7eb4-ecae-ac2a-f77e4841c345"
            data-autoplay-limit="0"
            data-nav-spacing="3"
            data-duration="500"
            data-infinite="false"
          >
            {/* 卡片 */}
            <div
              className="timeline-slider-mask w-slider-mask"
              id="w-slider-mask-0"
            >
              <div
                className={`testimonial-slide-card-wrapper left ${styles.wSlide}`}
              >
                <div
                  className="display-2 mg-bottom-24px"
                  dangerouslySetInnerHTML={{ __html: t('h-6-sub1-t') }}
                ></div>

                <ul className={`mg-bottom-0 ${styles.pl20}`}>
                  <li
                    dangerouslySetInnerHTML={{ __html: t('h-6-sub1-li1') }}
                  ></li>
                  <li
                    dangerouslySetInnerHTML={{ __html: t('h-6-sub1-li2') }}
                  ></li>
                  <li
                    dangerouslySetInnerHTML={{ __html: t('h-6-sub1-li3') }}
                  ></li>
                  <li
                    dangerouslySetInnerHTML={{ __html: t('h-6-sub1-li4') }}
                  ></li>
                </ul>
              </div>
              <div
                className={`testimonial-slide-card-wrapper center ${styles.wSlide}`}
              >
                <div
                  className="display-2 mg-bottom-24px"
                  dangerouslySetInnerHTML={{ __html: t('h-6-sub2-t') }}
                ></div>
                <ul className={`mg-bottom-0 ${styles.pl20}`}>
                  <li
                    dangerouslySetInnerHTML={{ __html: t('h-6-sub2-li1') }}
                  ></li>
                  <li
                    dangerouslySetInnerHTML={{ __html: t('h-6-sub2-li2') }}
                  ></li>
                  <li
                    dangerouslySetInnerHTML={{ __html: t('h-6-sub2-li3') }}
                  ></li>
                  <li
                    dangerouslySetInnerHTML={{ __html: t('h-6-sub2-li4') }}
                  ></li>
                </ul>
              </div>

              <div
                className={`testimonial-slide-card-wrapper right ${styles.wSlide}`}
              >
                <div
                  className="display-2 mg-bottom-24px"
                  dangerouslySetInnerHTML={{ __html: t('h-6-sub3-t') }}
                ></div>
                {/* <h3 className="display-4 mg-bottom-16px">
                    {t('h-6-sub3-desc')}
                  </h3> */}
                <ul className={`mg-bottom-0 ${styles.pl20}`}>
                  <li>{t('h-6-sub3-li1')}</li>
                  <li>{t('h-6-sub3-li2')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
