import { useFm } from '@better-bit-fe/base-hooks';
import React, { useEffect, useState, PropsWithChildren } from 'react';
import getConfig from 'next/config';
import DownloadIcons from '~/components/downloadIcons';
import { IRefer } from '~/types';
import styles from './index.module.less';
const { staticFolder } = getConfig().publicRuntimeConfig;

const Security = (props: PropsWithChildren<IRefer>) => {
  const t = useFm();

  return (
    <section className="cta-section cta-v1 wf-section">
      <div className="container-default z-index-1 w-container">
        <div className="inner-container _500px---mbl center">
          <div className="w-layout-grid grid-2-columns cta-v1">
            <div
              id="w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce67-1c2c5e56"
              data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1cce67"
              className="position-relative"
            >
              {/* 图片1 */}
              <div
                data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1cce68"
                className={`image-wrapper cta-v1-top-image ${styles.topImg}`}
              >
                <img
                  src={`${staticFolder}/mufex/images/connect/1.png`}
                  loading="eager"
                  alt="MUFEX wallet"
                  className="image "
                />
              </div>
              {/* 图片2 */}
              <div
                data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1cce6a"
                className={`image-wrapper cta-v1-bottom-image float ${styles.bottomImg}`}
              >
                <img
                  src={`${staticFolder}/mufex/images/connect/2.png`}
                  loading="eager"
                  alt="MUFEX bitcoin"
                  className="image "
                />
              </div>
              {/* 图片3 */}
              <div
                data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1cce6c"
                className="image-wrapper cta-v1-bottom-float float"
              >
                <img
                  src={`${staticFolder}/mufex/images/connect/3.svg`}
                  loading="eager"
                  alt="MUFEX-eth"
                  className="image "
                />
              </div>
              {/* 图片4 */}
              <div
                data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1cce6e"
                className="image-wrapper cta-v1-top-float float"
              >
                <img
                  src={`${staticFolder}/mufex/images/connect/4.svg`}
                  loading="eager"
                  alt="MUFEX-asset"
                  className="image "
                />
              </div>
              {/* 图片5 */}
              <div
                data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1cce70"
                className="image-wrapper cta-v1-middle-float main"
              >
                <img
                  src={`${staticFolder}/mufex/images/connect/5.svg`}
                  loading="eager"
                  alt="protfolio"
                  className="image"
                />
              </div>
            </div>
            {/* 右边 */}
            <div
              id="w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce72-1c2c5e56"
              data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1cce72"
              className="inner-container _545px"
            >
              <div
                id="w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce73-1c2c5e56"
                className="cta-v1-content-left-wrapper"
              >
                <h2 className="display-2 color-neutral-800">{t('h-5-t')}</h2>

                <div className="inner-container _470px">
                  <ul className="color-neutral-700 mg-bottom-40px">
                    <li style={{ textAlign: 'left' }}> {t('h-5-li1')} </li>
                    <li style={{ textAlign: 'left' }}> {t('h-5-li2')} </li>
                  </ul>
                </div>

                <DownloadIcons dark={true}></DownloadIcons>

                {/* <div className="buttons-row">
                  <a
                    target="_blank"
                    className="btn-primary download-app button-row _16px w-inline-block"
                    href="/downloadApp"
                    rel="noreferrer"
                  >
                    <div>{t('download')}</div>
                    <div className="social-icon-font button-right"></div>
                    <div className="social-icon-font button-right"></div>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Security;
