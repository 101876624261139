import { useFm } from '@better-bit-fe/base-hooks';
import React, { useEffect, useState, PropsWithChildren } from 'react';
import getConfig from 'next/config';
import { IRefer } from '~/types';
import styles from './index.module.less';
import { getDashboardData } from '~/api';
import { formatThousandDigit } from '~/utils';
const { staticFolder } = getConfig().publicRuntimeConfig;

const HomeData = (props: PropsWithChildren<IRefer>) => {
  const t = useFm();
  const [data, setData] = useState({
    turnoverTotal: '',
    openInterest: '',
    tradeUserTotal: '',
    totalTradesNumber: ''
  });
  useEffect(() => {
    async function getData() {
      const data = await getDashboardData();
      setData(data);
    }
    getData();
  }, []);

  return (
    <section className="section top-120px  wf-section  bg-secondary-3">
      <div className="container-default w-container">
        <div
          data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1ccff1"
          className="mg-bottom-48px w-dyn-list"
        >
          <div
            role="list"
            className={`${styles.borderClass} grid-2-columns  w-dyn-items`}
          >
            <div
              id="w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1ccff3-1c2c5e56"
              role="listitem"
              className="w-dyn-item"
            >
              <div className="blog-card-image-wrapper inside-card">
                <img
                  src={`${staticFolder}/mufex/images/data/1.svg`}
                  loading="eager"
                  alt="MUFEX-data"
                  className="blog-card-image"
                />
              </div>
            </div>

            {/* 缺数据 */}
            <div
              id="w-node-fb2f252d-2a0b-2aba-3cbe-192e8653d172-baf4a44e"
              data-w-id="fb2f252d-2a0b-2aba-3cbe-192e8653d172"
              // className="card _24px"
              className={styles.dataSectionRightClass}
            >
              <div
                role="listitem"
                className="career-card-item career-card-item-self  w-dyn-item"
              >
                <a
                  data-w-id="9120cd68-75aa-bb57-2eb8-0de2cb331a54"
                  className="career-card w-inline-block"
                >
                  <div>
                    <h2 className="mg-bottom-12px hover-h3">
                      {`$ ${formatThousandDigit(data.turnoverTotal)}`}
                    </h2>
                    <div className="details-wrapper wrap-mobile">
                      <div className="text-200 color-neutral-400">
                        {t('h-3-d1')}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div
                role="listitem"
                className="career-card-item  career-card-item-self w-dyn-item"
              >
                <a
                  data-w-id="9120cd68-75aa-bb57-2eb8-0de2cb331a54"
                  className="career-card w-inline-block"
                >
                  <div>
                    <h2 className="mg-bottom-12px hover-h3">
                      {`$ ${formatThousandDigit(data.openInterest)}`}
                    </h2>
                    <div className="details-wrapper wrap-mobile">
                      <div className="text-200  color-neutral-400">
                        {t('h-3-d2')}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div
                role="listitem"
                className="career-card-item career-card-item-self w-dyn-item"
              >
                <a
                  data-w-id="9120cd68-75aa-bb57-2eb8-0de2cb331a54"
                  className="career-card w-inline-block"
                >
                  <div>
                    <h2 className="mg-bottom-12px  hover-h3">
                      {`${formatThousandDigit(data?.totalTradesNumber)}`}
                    </h2>
                    <div className="details-wrapper wrap-mobile">
                      <div className="text-200  color-neutral-400">
                        {t('h-3-d4')}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="blog-card-image-wrapper inside-card"></div>
          </div>
          {/* <div className={`${styles.dataTipClass} text-200  color-neutral-400`}>
            {t('dataFromTip')}
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default HomeData;
