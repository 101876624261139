import { useFm } from '@better-bit-fe/base-hooks';
import React, { useEffect, useState, PropsWithChildren } from 'react';
import getConfig from 'next/config';
import { IRefer } from '~/types';
import styles from './index.module.less';
const { staticFolder } = getConfig().publicRuntimeConfig;

const PerpetualFutures = (props: PropsWithChildren<IRefer>) => {
  const t = useFm();
  const [activeTab, setActiveTable] = useState(1);
  const handleChangeActive = (val) => {
    if (val === activeTab) {
      return;
    }
    setActiveTable(val);
  };

  const TabSingle = (tabIndex) => {
    return (
      <a
        className={`tab-link  w-inline-block w-tab-link ${styles.tabItem} ${
          activeTab !== tabIndex && styles.tabNoActive
        }  ${tabIndex == 2 && 'middle'}`}
      >
        <div
          className={`tab-content-wrapper  ${
            activeTab !== tabIndex && styles.tabNoActive
          }`}
        >
          <div className="tab-image-wrapper">
            <img
              loading="eager"
              src={`${staticFolder}/mufex/images/future/${tabIndex}-left-${
                activeTab === tabIndex ? 'selected' : 'default'
              }.svg`}
              alt="Best Price Execution"
              className="feature-image"
            />
          </div>
          <div>
            <h3 className={`mg-bottom-12px ${styles.h3}`}>
              {t(`h-3-new-sub${tabIndex}-t`)}
            </h3>
            {/* 折叠内容 */}
            <div
              className={`tab-body ${
                activeTab !== tabIndex && styles.bodyNoActive
              }`}
            >
              <p
                className={`color-neutral-400 mg-bottom-0 ${styles.lineHeight28}`}
              >
                {t(`h-3-new-sub${tabIndex}-desc`)}
              </p>
            </div>
          </div>
          {/* -+ */}
          <div
            className={`tab-icon-wrapper ${
              activeTab !== tabIndex && styles.iconNoActive
            }`}
            onClick={() => handleChangeActive(tabIndex)}
          >
            {activeTab === tabIndex ? (
              <svg
                width="20"
                height="3"
                viewBox="0 0 20 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.63965H19"
                  stroke="#26F2D1"
                  strokeWidth="2"
                  strokeLinecap="square"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2.63965V20.6396"
                  stroke="#26F2D1"
                  strokeWidth="2"
                  strokeLinecap="square"
                />
                <path
                  d="M3 11.6396H21"
                  stroke="#26F2D1"
                  strokeWidth="2"
                  strokeLinecap="square"
                />
              </svg>
            )}
          </div>
        </div>
      </a>
    );
  };

  return (
    <section className={`section large wf-section ${styles.py160}`}>
      <div className="container-default w-container">
        <div className="inner-container _650px---tablet center _100---mbl">
          {/* title */}
          <div
            data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1cce86"
            className="inner-container _650px---table center "
          >
            <div className="inner-container _500px---mbl center">
              <div className="text-center---tablet">
                <div
                  data-w-id="d8eeb1fe-fe65-4bc9-67d8-d1be98f314b6"
                  className="w-layout-grid grid-2-columns title-and-buttons"
                >
                  <div className="full-width-tablet">
                    <h2
                      className={`display-2 mg-bottom-40px ${styles.mb48}`}
                      dangerouslySetInnerHTML={{ __html: t('h-3-new-t') }}
                    ></h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 内容 */}
          <div
            data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1cce8c"
            className="card _24px mg-bottom-48px"
          >
            <div className="w-layout-grid grid-2-columns _1-col-tablet gap-0">
              {/* right */}
              <div
                id="w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce93-1c2c5e56"
                data-easing="ease"
                data-duration-in="300"
                data-duration-out="100"
                className="w-tabs"
              >
                <div className="tabs-menu-wrapper w-tab-menu">
                  {TabSingle(1)}
                  {TabSingle(2)}
                  {TabSingle(3)}
                </div>
              </div>
              {/* right */}
              <div
                id="w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce8e-1c2c5e56"
                className={`lightbox-wrapper ${styles.rightImg}`}
              >
                {/* <img
                  src={`${staticFolder}/mufex/images/future/${activeTab}-bg.png`}
                  loading="eager"
                  alt=""
                  className="video-bg-image"
                /> */}
                <img
                  src={`${staticFolder}/mufex/images/future/2-bg.png`}
                  loading="eager"
                  alt=""
                  className="video-bg-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PerpetualFutures;
