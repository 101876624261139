import { useFm } from '@better-bit-fe/base-hooks';
import React, { useEffect, useState, PropsWithChildren } from 'react';
import getConfig from 'next/config';
import { IRefer } from '~/types';
import styles from './index.module.less';
const { staticFolder } = getConfig().publicRuntimeConfig;

const Advantage = (props: PropsWithChildren<IRefer>) => {
  const t = useFm();

  return (
    <section className="section  wf-section bg-secondary-3">
      <div className="container-default w-container">
        {/*  */}
        <div className="mg-bottom-48px">
          <div className="inner-container _650px---tablet center">
            <div className="inner-container _500px---mbl center">
              <div className="text-center---tablet">
                <div
                  data-w-id="d8eeb1fe-fe65-4bc9-67d8-d1be98f314b6"
                  className="w-layout-grid grid-2-columns title-and-buttons"
                >
                  <div className="full-width-tablet">
                    <h2
                      className="display-2 mg-bottom-0"
                      dangerouslySetInnerHTML={{ __html: t('h-4-t') }}
                    ></h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          data-w-id="22851834-a059-253a-02cd-e9c0627db8fc"
          className="w-layout-grid card features-card-wrapper"
        >
          {/* 卡片iphone */}
          <div
            id="w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce3f-1c2c5e56"
            className="feature-card large"
          >
            <div className="feature-card-large-content">
              <h3 className="display-4 mg-bottom-12px">{t('h-4-sub1-t')}</h3>
              <p className={`mg-bottom-24px ${styles.lineHeight28}`}>
                {t('h-4-sub1-desc')}
              </p>
            </div>
            <div className="image-wrapper feature-image">
              <img
                src={`${staticFolder}/mufex/images/user/iphone.png`}
                loading="eager"
                // sizes="(max-width: 479px) 100vw, (max-width: 767px) 56vw, (max-width: 991px) 28vw, (max-width: 1439px) 22vw, 281.375px"
                // srcSet={`${staticFolder}/mufex/images/user/image-mockup-03-finantech-x-webflow-template-p-500.png 500w, /v2/mufex/images/user/image-mockup-03-finantech-x-webflow-template-p-800.png 800w, /v2/mufex/images/user/image-mockup-03-finantech-x-webflow-template-p-1080.png 1080w, /v2/mufex/images/user/image-mockup-03-finantech-x-webflow-template.png 1114w`}
                alt="MUFEX-iphone"
                className="image"
              />
            </div>
          </div>
          {/* 卡片1 */}
          <div className="feature-card top-right">
            <div className="inner-container _400px---mbl">
              <img
                src={`${staticFolder}/mufex/images/user/1.svg`}
                loading="eager"
                alt="Always Self-Custodial"
                className="mg-bottom-24px"
              />
              <h3 className="display-4 mg-bottom-12px">{t('h-4-sub2-t')}</h3>
              <p
                className={`mg-bottom-12px ${styles.lineHeight28}`}
                dangerouslySetInnerHTML={{ __html: t('h-4-sub2-desc') }}
              ></p>
              <p className={`mg-bottom-0 ${styles.btnLink}`}>
                <a href="/earn">
                  <span>{t('h-4-sub2-btn')}</span>
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.85944 2.85938L14.5 8.49995L8.85944 14.1405"
                      stroke="#26F2D1"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.4999 8.5L2.5 8.5"
                      stroke="#26F2D1"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </p>
            </div>
          </div>
          {/* 卡片2 */}
          <div className="feature-card bottom-left">
            <div className="inner-container _400px---mbl">
              <img
                src={`${staticFolder}/mufex/images/user/2.svg`}
                loading="eager"
                alt="MUFEX-security"
                className="mg-bottom-24px "
              />
              <h3 className="display-4 mg-bottom-12px">{t('h-4-sub3-t')}</h3>
              <p className={`mg-bottom-12px ${styles.lineHeight28}`}>
                {t('h-4-sub3-desc')}
              </p>
            </div>
          </div>
          {/* 卡片3 */}
          <div className="feature-card middle">
            <div className="inner-container _400px---mbl">
              <img
                src={`${staticFolder}/mufex/images/user/3.svg`}
                loading="eager"
                alt="Lightning Fast Experience"
                className="mg-bottom-24px"
              />

              <h3
                className="display-4 mg-bottom-12px"
                dangerouslySetInnerHTML={{ __html: t('h-4-sub4-t') }}
              ></h3>
              <p className={`mg-bottom-0 ${styles.lineHeight28}`}>
                {t('h-4-sub4-desc')}
              </p>
            </div>
          </div>
          {/* 卡片4 */}
          <div className="feature-card">
            <div className="inner-container _400px---mbl">
              <img
                src={`${staticFolder}/mufex/images/user/4.svg`}
                loading="eager"
                alt="Low Fee"
                className="mg-bottom-24px"
              />
              <h3 className="display-4 mg-bottom-12px">{t('h-4-sub5-t')}</h3>

              <p
                className={`mg-bottom-0 ${styles.lineHeight28}`}
                dangerouslySetInnerHTML={{ __html: t('h-4-sub5-desc') }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advantage;
