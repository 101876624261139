export { default as Footer } from './footer';
export { default as Partner } from './partner';
export { default as HomeBanner } from './homeBanner';
export { default as Advantage } from './advantage';
export { default as Roadmap } from './roadmap';
export { default as Security } from './security';
export { default as HomeData } from './homeData';
export { default as PerpetualFutures } from './perpetualFutures';
export { default as Announcement } from './announcement';
export { default as HoverComp } from './hoverComp';
