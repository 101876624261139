// @ts-nocheck
export function formatThousandDigit(val: string) {
  if (val?.includes('.')) {
    const splitValue = val.split('.');
    const formatInt = Number(splitValue[0]).toLocaleString();
    const decimals = splitValue[1];
    return formatInt + '.' + decimals;
  } else {
    return Number(val).toLocaleString();
  }
}

export function emailCheck(val: string) {
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(val);
}

export function getLang() {
  if (typeof window !== 'undefined') {
    const langReg = /([a-z]{2}-[A-Z]{2})/;
    let refLang = location.href.match(langReg);
    if (refLang) {
      refLang = refLang[0];
    }
    const lang = refLang || localStorage.getItem('LANG_KEY') || 'en-US';
    //获取到之后set
    localStorage.setItem('LANG_KEY', lang);
    return lang;
  }
  return 'en-US';
}

export const getUrlAfterLangChange = (path) => {
  if (typeof window !== 'undefined') {
    const langReg = /([a-z]{2}-[A-Z]{2,3})/;
    let lang = getLang();
    // 导航上有语言，直接拼接
    //如果是en-US/aboutUs点击呢
    // if (langReg.test(location.href)) {
    //   lang = location.href.match(langReg)[0];
    // }

    const newUrl = `/${lang}${path}/`;
    return newUrl;
  }
  console.log('newUrl no broswer', path);
  return path;
};
