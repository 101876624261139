import { useFm } from '@better-bit-fe/base-hooks';
import React, { PropsWithChildren } from 'react';
import getConfig from 'next/config';
import { IRefer } from '~/types';
import Slider from '@ant-design/react-slick';
import styles from './index.module.less';

const { staticFolder } = getConfig().publicRuntimeConfig;

const Announcement = (props: PropsWithChildren<IRefer>) => {
  const t = useFm();

  const noticeList = [
    // {
    //   content: 'notice1',
    //   link: 'https://www.mufex.finance/trade/usdt/BTCUSDT'
    // },
    // {
    //   content: 'notice2',
    //   link: 'https://docs.mufex.finance/mufex/support/mufex-affiliate-program'
    // }
    // {
    //   content: 'notice3',
    //   link: 'https://blog.mufex.finance/mufex-mainnet-launch-deposit-and-trade-to-enjoy-usdt-20-annualized-return-6e6922b4fdf3'
    // }
    {
      content: 'notice4',
      link: 'http://t.me/mufex_bot'
    }
  ];

  const settings = {
    dots: false,
    infinite: true,
    vertical: true,
    arrows: false,
    slidesToShow: 1, //只展示一条
    slidesToScroll: 1, //每次只滚一个
    autoplay: true,
    autoplaySpeed: 4000
  };

  return (
    <div className={styles.announcement}>
      <div className={styles.sliderWrapper}>
        {/* @ts-ignore */}
        <Slider {...settings}>
          {noticeList.map((it, i) => {
            return (
              <div key={i} className={styles.itemCenter}>
                <div className={styles.itemWidth}>
                  <a href={it.link} className={styles.itemWrapper}>
                    <img
                      className={styles.trumpetIcon}
                      src={`${staticFolder}/mufex/images/announcement/trumpet.svg`}
                      alt="trumpet"
                    />
                    <div className={styles.itemText}>{t(it.content)} </div>
                    <svg
                      className={styles.itemSvg}
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.66666 13.1667L10.3333 8.49999L5.66666 3.83332"
                        stroke="black"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Announcement;
