// @ts-ignore
import { useFm } from '@better-bit-fe/base-hooks';
import React, { useEffect, useState, PropsWithChildren } from 'react';
import getConfig from 'next/config';
import DownloadIcons from '~/components/downloadIcons';
import { IRefer } from '~/types';
import styles from './index.module.less';
const { staticFolder } = getConfig().publicRuntimeConfig;

const HomeBanner = (props: PropsWithChildren<IRefer>) => {
  const t = useFm();

  return (
    <section className={`section hero-v1 wf-section ${styles.homeBanner}`}>
      <div className="container-default w-container">
        <div className="inner-container _650px---tablet center _100---mbl">
          <div className="text-center---tablet">
            <div className="w-layout-grid grid-2-columns home-hero-grid">
              <div
                data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1ccdba"
                className="inner-container _515px _100---tablet"
              >
                <div className="inner-container _550px---mbl center">
                  <h1
                    className="display-1"
                    dangerouslySetInnerHTML={{ __html: t('h-1-t') }}
                  ></h1>
                  <div className="inner-container _550px---tablet center">
                    <div className="inner-container _500px---mbl center">
                      <div className="inner-container _500px full-width-tablet">
                        <p
                          className="mg-bottom-40px"
                          dangerouslySetInnerHTML={{ __html: t('h-1-desc') }}
                        ></p>
                      </div>
                      <div className="buttons-row center---tablet">
                        <a
                          href="/trade/usdt/BTCUSDT"
                          className={`btn-primary button-row w-button ${styles.btn}`}
                        >
                          <span>{t('h-1-btn')}</span>
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.4492 3.44922L17.4999 10.4999L10.4492 17.5507"
                              stroke="black"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M17.4999 10.5L2.5 10.5"
                              stroke="black"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </a>
                      </div>
                      <div
                        className="buttons-row center---tablet"
                        style={{ marginTop: '48px' }}
                      >
                        <DownloadIcons />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1ccdc6"
                className="hero-image-container"
              >
                {/* 图片1*/}
                <div
                  data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1ccdc7"
                  className="image-wrapper hero-float-bottom-right"
                >
                  <img
                    src={`${staticFolder}/mufex/images/banner/1.png`}
                    loading="eager"
                    alt="MUFEX-bitcoin"
                  />
                </div>
                {/* 图片5 */}
                <img
                  src={`${staticFolder}/mufex/images/banner/5.png`}
                  loading="eager"
                  data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1ccdc9"
                  alt="MUFEX-eth"
                  className="position-relative main"
                ></img>
                {/* 图片2 */}
                <div
                  data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1ccdca"
                  className="image-wrapper hero-float-bottom-left float"
                >
                  <img
                    src={`${staticFolder}/mufex/images/banner/2.png`}
                    loading="eager"
                    alt="MUFEX-btc"
                  />
                </div>
                {/* 图片3 */}
                <div
                  data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1ccdcc"
                  className="image-wrapper hero-float-top-left main"
                >
                  <img
                    src={`${staticFolder}/mufex/images/banner/3.png`}
                    loading="eager"
                    alt="MUFEX-coin"
                  />
                </div>
                {/* 图片4 */}
                <div
                  data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1ccdce"
                  className="image-wrapper hero-float-top-right float"
                >
                  <img
                    src={`${staticFolder}/mufex/images/banner/4.png`}
                    loading="eager"
                    alt="MUFEX-symbol"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
