import { useFm } from '@better-bit-fe/base-hooks';
import getConfig from 'next/config';
import { IRefer } from '~/types';
import { PropsWithChildren } from 'react';
import styles from './index.module.less';
const { staticFolder } = getConfig().publicRuntimeConfig;

const Partner = (props: PropsWithChildren<IRefer>) => {
  const t = useFm();

  return (
    <section className="section pd-128px wf-section bg-secondary-3">
      <div
        data-w-id="cd49c5f0-c3b7-c48d-73ff-0422dd1ccdd1"
        className="container-default w-container"
      >
        <div className="text-center mg-bottom-72px">
          <h2
            className="display-2"
            dangerouslySetInnerHTML={{ __html: t('h-2-t') }}
          ></h2>
        </div>
      </div>
      <div className={`${styles.partnerClass} `}>
        <div className={styles.imgWrapperClass}>
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/aribitrum.svg`}
            loading="eager"
            alt="MUFEX-partners-arbitrum"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/polygon.svg`}
            loading="eager"
            alt="MUFEX-partners-polygon"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/chainlink.svg`}
            loading="eager"
            alt="MUFEX-partners-hainlink"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/unipass.svg`}
            loading="eager"
            alt="MUFEX-partners-unipass"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/scroll.svg`}
            loading="eager"
            alt="MUFEX-partners-scroll"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/afflux.svg`}
            loading="eager"
            alt="MUFEX-partners-afflux"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/beosin.svg`}
            loading="eager"
            alt="MUFEX-partners-beosin"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/c-cubed.svg`}
            loading="eager"
            alt="MUFEX-partners-c-cubed"
          />

          <img
            src={`${staticFolder}/mufex/images/partnersLogo/metatrust.svg`}
            loading="eager"
            alt="MUFEX-partners-metatrust"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/particle.svg`}
            loading="eager"
            alt="MUFEX-partners-particle-network"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/numen.svg`}
            loading="eager"
            alt="MUFEX-partners-numen"
          />

          <img
            src={`${staticFolder}/mufex/images/partnersLogo/rugrap.svg`}
            loading="eager"
            alt="MUFEX-partners-rugrap"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/secure3.svg`}
            loading="eager"
            alt="MUFEX-partners-secure3"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/hxr.svg`}
            loading="eager"
            alt="MUFEX-partners-HXR trade"
          />
          {/* 重复的7张 */}
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/aribitrum.svg`}
            loading="eager"
            alt="MUFEX-partners-arbitrum"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/polygon.svg`}
            loading="eager"
            alt="MUFEX-partners-polygon"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/chainlink.svg`}
            loading="eager"
            alt="MUFEX-partners-hainlink"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/unipass.svg`}
            loading="eager"
            alt="MUFEX-partners-unipass"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/scroll.svg`}
            loading="eager"
            alt="MUFEX-partners-scroll"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/afflux.svg`}
            loading="eager"
            alt="MUFEX-partners-afflux"
          />
          <img
            src={`${staticFolder}/mufex/images/partnersLogo/beosin.svg`}
            loading="eager"
            alt="MUFEX-partners-beosin"
          />
        </div>
      </div>
    </section>
  );
};

export default Partner;
